.App {
  
  justify-content: center;
  align-items: center;
}

.back{
  background-image: url(./img/bible6.jpg);
  width: 90vw;
  height: 90vh;
  margin: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(23, 22, 22, 0.3) 0px 18px 36px -18px;
  margin-top: 2%;
  border-radius: 15px;

}
 .header{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 4rem;
  color: rgb(19, 19, 65);
 }


 .score{
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 3rem;
  color: white;
 }

 .question-number{
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .question{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: justify ;
  width: 100%;
  font-size: 3rem;
  font-weight: 500;
  color: rgb(15, 152, 44);
 }
.question-card {
  /* Center the div  */
  margin: 0 auto;

  /* Dimensions  */
  width: 80%; /* Can be in percentage also. */
  height: auto;

  /* Visual  */
 
  

  border-radius: 16px;
  color: white;
  
}

ul {
  list-style: none;
}

li {
  margin-top: 8px;
  background-color: darkgray;
  padding: 16px;
  border: 3px solid white;
  border-radius: 20px;
  font-size: 20px;
}



/* Final Results  */

.final-results {
  /* Center the div  */
  margin: 0 auto;

  /* Dimensions  */
  width: 50%; /* Can be in percentage also. */
  height: auto;
  margin-top: 64px;

  /* Visual  */
  background-color: gray;
  padding: 16px;
  border-radius: 16px;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
.resultat{
  display: flex;
  justify-content: space-between;
  padding: 20px;

}
.final{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
button {
  background-color: red; /* Green */
  border: none;
  color: white;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;

  border-radius: 24px;
}
